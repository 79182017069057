// SPDX-FileCopyrightText: © 2024 Schneider Electric
//
// SPDX-License-Identifier: Apache-2.0

const toClassList = (value?: Element | string): DOMTokenList | undefined => {
  if (value === undefined) return document.documentElement.classList
  if (typeof value === 'string')
    return document.querySelector(value)?.classList ?? undefined
  return value.classList
}

const getVariant = (
  type: string,
  classList?: DOMTokenList | undefined,
): string | undefined => {
  if (!classList) return undefined

  const prefix = `qds-${type}-`

  return [...classList]
    .find((value: string): value is string => value.startsWith(prefix))
    ?.replace(prefix, '')
}

const setVariant = (
  type: string,
  value: string,
  element?: Element | string,
): void => {
  const classList = toClassList(element)
  if (classList === undefined) return

  const prefix = `qds-${type}-`

  const currentVariant = getVariant(type, classList)
  if (currentVariant !== undefined)
    classList.remove(`${prefix}${currentVariant}`)
  classList.add(`${prefix}${value}`)
}

type NonEmpty<T extends string = string> = T extends '' ? never : T

/**
 * Get the currently set platform.
 *
 * @param element The element where the platform will be retrieved from. Can be
 * either:
 *
 * - A non-empty CSS selector string
 * - A reference to an
 * [`Element`](https://developer.mozilla.org/docs/Web/API/Element)
 *
 * By default, this is the root node of the current document.
 *
 * @returns The currently set platform if one is set, `undefined` otherwise.
 */
export const getPlatform = <S extends string, E extends Element = Element>(
  element?: E | NonEmpty<S>,
): string | undefined => getVariant('platform', toClassList(element))

/**
 * Sets the current platform.
 *
 * The current platform represented by a `qds-platform-`-prefixed CSS class
 * will be removed.
 *
 * @param platform The platform to set. Must be a non-empty value.
 * @param element The element where the platform will be set. Can be either:
 *
 * - A non-empty CSS selector string
 * - A reference to an
 * [`Element`](https://developer.mozilla.org/docs/Web/API/Element)
 *
 * By default, this is the root node of the current document.
 */
export const setPlatform = <
  P extends string,
  S extends string = string,
  E extends Element = Element,
>(
  platform: NonEmpty<P>,
  element?: E | NonEmpty<S>,
): void => {
  setVariant('platform', platform, element)
}

/**
 * Get the currently set theme.
 *
 * @param element The element where the theme will be retrieved from. Can be
 * either:
 *
 * - A non-empty CSS selector string
 * - A reference to an
 * [`Element`](https://developer.mozilla.org/docs/Web/API/Element)
 *
 * By default, this is the root node of the current document.
 *
 * @returns The currently set theme if one is set, `undefined` otherwise.
 */
export const getTheme = <S extends string, E extends Element = Element>(
  element?: E | NonEmpty<S>,
): string | undefined => getVariant('theme', toClassList(element))

/**
 * Sets the current theme.
 *
 * The current theme represented by a `qds-theme-`-prefixed CSS class will be
 * removed.
 *
 * @param theme The theme to set. Must be a non-empty value.
 * @param element The element where the theme will be set. Can be either:
 *
 * - A non-empty CSS selector string
 * - A reference to an
 * [`Element`](https://developer.mozilla.org/docs/Web/API/Element)
 *
 * By default, this is the root node of the current document.
 */
export const setTheme = <
  T extends string,
  S extends string = string,
  E extends Element = Element,
>(
  theme: NonEmpty<T>,
  element?: E | NonEmpty<S>,
): void => {
  setVariant('theme', theme, element)
}
